import { ComponentPortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

import { NGXLogger } from 'ngx-logger';
import {
  distinctUntilChanged,
  Subscription,
} from 'rxjs';

import { PeriodicHeathCheckService } from '@core/feeds/periodic-heath-check.service';
import { GRID_TYPE_TOKEN } from '@core/grid-type-portal-tokens';
import { TelemetryEvents } from '@core/models/telemetry.enum';
import { LoadingService } from '@core/services/loading.service';
import { ProgressIndicatorService } from '@core/services/progress-indicator.service';
import { TelemetryService } from '@core/services/telemetry.service';

import { SelectableAssetGroupModel } from '../../../asset-hierarchy/models/selectable-asset-group.model';
import { AssetHierarchyFacadeService } from '../../../asset-hierarchy/services/asset-hierarchy-facade.service';
import { CardToggleGroupType } from '../../../card-toggle-group/models/card-toggle-group.type';
import type { MfrrGridsContainerComponent } from '../../../mfrr-grids/containers/mfrr-grids-container/mfrr-grids-container.component';
import type { NopSummaryContainerComponent } from '../../../nop-summary/containers/nop-summary-container/nop-summary-container.component';
import type { InboxContainerComponent } from '../../../notifications/containers/inbox-container/inbox-container.component';
import { InboxPanelService } from '../../../notifications/services/inbox-panel.service';
import { NotificationsFacadeService } from '../../../notifications/services/notifications-facade.service';
import type { SetPointsSidenavContainerComponent } from '../../../set-points-sidenav/containers/set-points-sidenav-container/set-points-sidenav-container.component';
import { SetPointsFacadeService } from '../../../set-points-sidenav/services/set-points-facade.service';
import { SummaryAssetContainerComponent } from '../../../summary-grid/containers/summary-asset-container/summary-asset-container.component';
import type { SummaryGraphAssetHierarchyContainerComponent } from '../../../summary-grid/containers/summary-graph-asset-hierarchy-container/summary-graph-asset-hierarchy-container.component';
import type { SummaryGridAssetHierarchyContainerComponent } from '../../../summary-grid/containers/summary-grid-asset-hierarchy-container/summary-grid-asset-hierarchy-container.component';
import { SummaryNopContainerComponent } from '../../../summary-grid/containers/summary-nop-container/summary-nop-container.component';

@Component({
  selector: 'app-home-container',
  templateUrl: './home-container.component.html',
  styleUrls: ['./home-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeContainerComponent implements OnInit, OnDestroy {
  @ViewChild('drawer') drawer?: MatDrawer;
  public assetGraphPortal?: ComponentPortal<SummaryGraphAssetHierarchyContainerComponent>;
  public assetGridPortal?: ComponentPortal<SummaryGridAssetHierarchyContainerComponent>;
  public assetContainer?: ComponentPortal<SummaryAssetContainerComponent>;
  public reserveBiddingGraphPortal?: ComponentPortal<SummaryGraphAssetHierarchyContainerComponent>;
  public reserveBiddingGridPortal?: ComponentPortal<SummaryGridAssetHierarchyContainerComponent>;
  public mfrrGridsPortal?: ComponentPortal<MfrrGridsContainerComponent>;
  public summaryNopContainerPortal?: ComponentPortal<SummaryNopContainerComponent>;
  public inboxPanelPortal?: ComponentPortal<InboxContainerComponent>;
  public setPointsPanelPortal?: ComponentPortal<SetPointsSidenavContainerComponent>;
  public nopSummaryPortal?: ComponentPortal<NopSummaryContainerComponent>;

  public selectedCardType: CardToggleGroupType = 'nop-summary';
  public selectedDrawerType?: 'inbox' | 'set-points';
  public isAssetGroupSelected = false;
  public showTimeseriesProgress$ = this.progressIndicator.showProgress$.pipe(distinctUntilChanged());
  public bannerNotifications$ = this.notificationsFacade.currentCriticalNotifications$;
  public loading$ = this.loadingService.loadingState$;

  private stateSubscriptions: Subscription[] = [];

  constructor(
    private notificationsFacade: NotificationsFacadeService,
    private inboxPanel: InboxPanelService,
    private setPointsPanel: SetPointsFacadeService,
    private assetHierarchyState: AssetHierarchyFacadeService,
    private readonly loadingService: LoadingService,
    private logger: NGXLogger,
    private telemetryService: TelemetryService,
    private progressIndicator: ProgressIndicatorService,
    private renderer: Renderer2,
    private heathCheckService: PeriodicHeathCheckService,
  ) {
  }

  public ngOnInit(): void {
    this.renderer.removeClass(document.body, 'theme-light');
    this.renderer.addClass(document.body, 'theme-dark');
    this.heathCheckService.initialize();

    this.stateSubscriptions = [
      // inbox panel subscription
      this.inboxPanel.showPanel$.subscribe((isVisible: boolean) => {
        this.selectedDrawerType = isVisible ? 'inbox' : undefined;

        if (isVisible) {
          this.loadInboxContainer().catch((error) => (
            this.logger.error('An error occured while trying to lazy load inbox module', error)
          ));
        } else {
          this.notificationsFacade.resetInboxState();
        }

        this.drawer?.toggle(isVisible);
      }),
      // set points panel subscription
      this.setPointsPanel.showPanel$.subscribe((isVisible: boolean) => {
        this.selectedDrawerType = isVisible ? 'set-points' : undefined;

        if (isVisible) {
          this.loadSetPointsContainer().catch((error) => (
            this.logger.error('An error occured while trying to lazy load set points module', error)
          ));
        }

        this.drawer?.toggle(isVisible);
      }),
      // asset hierarchy subscription
      this.assetHierarchyState.selectedAssetHierarchy$.subscribe((selectableAssetGroupModel: SelectableAssetGroupModel[]) => {
        this.isAssetGroupSelected = selectableAssetGroupModel.find((x) => x.isSelected) != null;
      }),
    ];
  }

  public ngOnDestroy(): void {
    this.stateSubscriptions.forEach((sub) => sub.unsubscribe());
    this.heathCheckService.cleanUpSubscriptions();
  }

  public onSelectCard(type: CardToggleGroupType): Promise<void> {
    this.telemetryService.logEvent(TelemetryEvents.CardSelectionChanged, { selectedCard: type });
    this.selectedCardType = type;
    return this.lazyLoadContent();
  }

  private async loadAssetHierarchyContainers(): Promise<void> {
    if (this.assetContainer) {
      return;
    }

    const summaryGridModuleRef = new (await import('../../../summary-grid/summary-grid.module')).SummaryGridModule();
    this.assetContainer = summaryGridModuleRef.createSummaryAssetContainer();
  }

  private async loadReserveBiddingContainers(selectedCardType :CardToggleGroupType): Promise<void> {
    const gridTypePortalInjector = Injector.create({
      providers: [{ provide: GRID_TYPE_TOKEN, useValue: selectedCardType }],
    });

    const summaryGridModuleRef = new (await import('../../../summary-grid/summary-grid.module')).SummaryGridModule();

    if (this.reserveBiddingGraphPortal && this.reserveBiddingGridPortal) {
      this.reserveBiddingGridPortal = summaryGridModuleRef.createSummaryGrid(gridTypePortalInjector);
      return;
    }

    this.reserveBiddingGraphPortal = summaryGridModuleRef.createSummaryGraph();
    this.reserveBiddingGridPortal = summaryGridModuleRef.createSummaryGrid(gridTypePortalInjector);

    const mfrrGridsModuleRef = new (await import('../../../mfrr-grids/mfrr-grids.module')).MfrrGridsModule();
    this.mfrrGridsPortal = mfrrGridsModuleRef.createMfrrGrids();
  }

  private async loadNopContainers(): Promise<void> {
    if (this.summaryNopContainerPortal) {
      return;
    }

    const moduleRef = new (await import('../../../summary-grid/summary-grid.module')).SummaryGridModule();
    this.summaryNopContainerPortal = moduleRef.createNopSummaryContainer();
  }

  private async loadInboxContainer(): Promise<void> {
    if (this.inboxPanelPortal) {
      return;
    }

    const moduleRef = new (await import('../../../notifications/notifications.module')).NotificationsModule();
    this.inboxPanelPortal = moduleRef.createInboxContainer();
  }

  private async loadSetPointsContainer(): Promise<void> {
    if (this.setPointsPanelPortal) {
      return;
    }

    const moduleRef = new (await import('../../../set-points-sidenav/set-points-sidenav.module')).SetPointsSidenavModule();
    this.setPointsPanelPortal = moduleRef.createSetPointsContainer();
  }

  private async loadNopSummaryContainer(): Promise<void> {
    if (this.nopSummaryPortal) {
      return;
    }

    const moduleRef = new (await import('../../../nop-summary/nop-summary.module')).NopSummaryModule();
    this.nopSummaryPortal = moduleRef.createNopSummaryContainer();
  }

  private lazyLoadContent(): Promise<void> {
    switch (this.selectedCardType) {
      case 'reserve-bidding':
        return this.loadReserveBiddingContainers(this.selectedCardType);
      case 'assets':
        return this.loadAssetHierarchyContainers();
      case 'position':
        return this.loadNopContainers();
      case 'nop-summary':
        return this.loadNopSummaryContainer();
      default:
        return Promise.resolve();
    }
  }
}
