import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private readonly loading$ = signal<boolean>(false);
  public readonly loadingState$ = this.loading$;

  public show(): void {
    this.loading$.set(true);
  }

  public hide(): void {
    this.loading$.set(false);
  }
}
