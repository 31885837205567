import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  JWT_OPTIONS,
  JwtModule,
} from '@auth0/angular-jwt';
import {
  AuthHttpInterceptor,
  AuthModule,
  AuthService,
} from '@auth0/auth0-angular';
import {
  LoggerModule,
  NgxLoggerLevel,
} from 'ngx-logger';
import { Observable } from 'rxjs';

import { environment as env } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpRequestInterceptor } from './core/interceptors/http-request.interceptor';
import { loadingInterceptor } from './core/interceptors/loading.interceptor';
import { ErrorModule } from './modules/error/error.module';
import { HomeModule } from './modules/home/home.module';
import { PortfolioHierarchyInterceptor } from './modules/settings/interceptors/portfolio-hierarchy.interceptor';

export function jwtOptionsFactory(tokenService: AuthService): {
  tokenGetter: () => Observable<string> | string;
} {
  return {
    tokenGetter: () => tokenService.getAccessTokenSilently(),
  };
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  bootstrap: [AppComponent],
  imports: [AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [AuthService],
      },
    }),
    AuthModule.forRoot({
      domain: env.auth0Domain,
      clientId: env.auth0ClientId,
      authorizationParams: {
        audience: env.auth0Audience,
        redirect_uri: window.location.origin,
      },
      errorPath: '/error',
      useRefreshTokens: true,
      httpInterceptor: {
        allowedList: (Object.keys(env) as (keyof (typeof env))[])
          .filter((key) => key.endsWith('Url'))
          .map((key) => `${String(env[key])}*`),
      },
    }),
    LoggerModule.forRoot({
      level: env.production ? NgxLoggerLevel.WARN : NgxLoggerLevel.TRACE,
      enableSourceMaps: !env.production,
    }),
    HomeModule,
    ErrorModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: PortfolioHierarchyInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([loadingInterceptor])),
  ],
})
export class AppModule {
}
