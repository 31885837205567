import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { environment } from '@env/environment';
import { finalize } from 'rxjs';

import { LoadingService } from '../services/loading.service';

export const loadingInterceptor: HttpInterceptorFn = (req, next) => {
  let activeRequests = 0;
  const loadingService = inject(LoadingService);

  if (req.url.includes(environment.auctionsApiUrl)) {
    activeRequests++;
    loadingService.show();

    return next(req).pipe(
      finalize(() => {
        activeRequests--;
        if (activeRequests === 0) {
          loadingService.hide();
        }
      }),
    );
  }

  return next(req);
};
