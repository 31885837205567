import { computed, Injectable, signal } from '@angular/core';

import {
  Auction,
  OrderSummary,
  RetrievedCurveOrderResult,
  AuctionState as AuctionResultState,
  AuctionIdentity,
} from '@bradyplc/brady.powerdesk.api.external.auctions.contracts';

import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, map, Observable, Subject } from 'rxjs';

import { AuctionResults, AuctionState, AuctionView } from '../models/state.interface';
import { PanelData } from '../models/sub-portfolio-drilldown.interface';

const initialState: AuctionState = {
  availableAuctions: [],
  availableAuctionSelected: undefined,
  view: 'summary',
  auctionSummaryGridState: { loading: false, lastUpdated: '-' },
  auctionOrderGridState: { loading: false },
  auctionSubmitGridState: { loading: false },
  auctionOrders: [],
  auctionOrderSelectedId: undefined,
  auctionCurveOrderDetailSelected: undefined,
  auctionResults: [],
  auctionResultSelectedId: undefined,
  selectedDeliveryName: '',
  selectedPortfolioId: '',
  selectedAuctionId: '',
  selectedParticipantId: '',
};

@Injectable({
  providedIn: 'root',
})
export class AuctionsStateService {
  private readonly auctionState$$ = new BehaviorSubject<AuctionState>(initialState);

  public containerActionIsVisible$ = signal<boolean>(true);
  public portfolioDrilldown$ = signal<PanelData[]>([]);
  public portfolioDrilldownSelected$ = signal<PanelData | undefined>(undefined);
  public isPortfolioDrilldown$ = computed(() => {
    if (!this.portfolioDrilldown$()?.length) {
      return false;
    }

    return !(this.portfolioDrilldown$()[0].isRoot && this.portfolioDrilldown$()[0].isLeaf);
  });

  public availableAuctions$ = this.auctionState$$.pipe(map((state) => state.availableAuctions));
  public auctionStateAuctionOrders$ = this.auctionState$$.pipe(map((state) => state.auctionOrders));

  public availableAuctionsFiltered$ = combineLatest([this.availableAuctions$, this.auctionStateAuctionOrders$]).pipe(
    map(([availableAuctions, auctionOrders]: [Auction[], OrderSummary[]]) => availableAuctions.filter(
      (auction) => !auctionOrders.some((order) => order.auctionIdentity.auctionTypeId === auction.auctionIdentity.auctionTypeId
        && order.auctionIdentity.productId === auction.auctionIdentity.productId),
    )),
  );

  public auctionState$ = this.auctionState$$.asObservable();
  public auctionStateView$ = this.auctionState$$.pipe(map((state) => state.view));
  public auctionStateAuctionSummaryGridState$ = this.auctionState$$.pipe(map((state) => state.auctionSummaryGridState));
  public auctionResults$ = this.auctionState$$.pipe(map((state) => state.auctionResults));
  public auctionResult$ = (auctionId: string): Observable<AuctionResults | undefined> => this.auctionResults$.pipe(
    map((results) => results.find((result) => result.auctionId === auctionId)),
  );

  public auctionResultState$ = (auctionId: string): Observable<AuctionResultState | undefined> => this.auctionResults$.pipe(
    map((results) => results.find((result) => result.auctionId === auctionId)?.auctionState),
  );

  public auctionOrderSelectedId$ = this.auctionState$$.pipe(map((state) => state.auctionOrderSelectedId));
  public auctionResultSelectedId$ = this.auctionState$$.pipe(map((state) => state.auctionResultSelectedId));
  public auctionStateAuctionCurveOrderDetailSelected$ = this.auctionState$$.pipe(map((state) => state.auctionCurveOrderDetailSelected));
  public selectedDeliveryName$ = this.auctionState$$.pipe(map((state) => state.selectedDeliveryName));
  public selectedPortfolioId$ = this.auctionState$$.pipe(map((state) => state.selectedPortfolioId));
  public auctionStateAuctionOrderGridState$ = this.auctionState$$.pipe(map((state) => state.auctionOrderGridState));
  public auctionStateAuctionSubmitGridState$ = this.auctionState$$.pipe(map((state) => state.auctionSubmitGridState));

  private readonly refreshSummaryOrder$$ = new BehaviorSubject<boolean>(false);
  public refreshSummaryOrder$ = this.refreshSummaryOrder$$.asObservable();

  private readonly refreshOrder$$ = new BehaviorSubject<boolean>(false);
  public refreshOrder$ = this.refreshOrder$$.asObservable();

  private readonly saveCurveOrder$$ = new Subject<boolean>();
  public saveCurveOrder$ = this.saveCurveOrder$$.asObservable();

  private readonly submitCurveOrder$$ = new Subject<boolean>();
  public submitCurveOrder$ = this.submitCurveOrder$$.asObservable();

  private readonly aggregateCurveOrder$$ = new Subject<boolean>();
  public aggregateCurveOrder$ = this.aggregateCurveOrder$$.asObservable();

  private readonly orderRevision$$ = new BehaviorSubject<{ orderId: string; revision: number }>({ orderId: '', revision: 0 });
  public orderRevision$ = this.orderRevision$$.asObservable();

  public getAvailableAuctions = (): Auction[] => this.auctionState$$.value.availableAuctions;
  public getSelectedOrderId = (): string | undefined => this.auctionState$$.value.auctionOrderSelectedId;
  public getSelectedResultId = (): string | undefined => this.auctionState$$.value.auctionResultSelectedId;
  public getPortfolioId = (): string => this.auctionState$$.value.selectedPortfolioId;
  public getAuctionId = (): string => this.auctionState$$.value.selectedAuctionId;
  public getParticipantId = (): string => this.auctionState$$.value.selectedParticipantId;
  public getCurveOrderDetail = (): RetrievedCurveOrderResult | undefined => this.auctionState$$.value.auctionCurveOrderDetailSelected;

  // Getting the result state from /retrieval end point for an up to date state on the result screen.
  public getAuctionOrderState = (
    auctionId: string,
  ): AuctionResultState => this.auctionState$$.value.auctionResults.find(
    (result) => result.auctionId === auctionId,
  )?.auctionState ?? AuctionResultState.None;

  // Gettting the result state from the /summary end point for logic needed on the view order screen.
  public getAuctionOrderSelectedResultState = (
    auctionOrderId: string,
  ): AuctionResultState => this.auctionState$$.value.auctionOrders.find(
    (auctionOrders) => auctionOrders.orderId === auctionOrderId,
  )?.auctionState ?? AuctionResultState.None;

  getAuctionView(): AuctionView {
    return this.auctionState$$.value.view;
  }

  getAvailableAuctionSelected(): Auction | undefined {
    return this.auctionState$$.value.availableAuctionSelected;
  }

  setInitialState(): void {
    this.auctionState$$.next(initialState);
    this.setRevision('', 0);
  }

  setRevision(orderId: string, revision: number): void {
    this.orderRevision$$.next({ orderId, revision });
  }

  setAvailableAuctions(availableAuctions: Auction[]): void {
    this.auctionState$$.next({ ...this.auctionState$$.value, availableAuctions });
  }

  setAvailableAuctionSelected(auctionIdentity: AuctionIdentity | undefined): void {
    if (!auctionIdentity) {
      return;
    }

    const availableAuctionSelected = this.auctionState$$.value.availableAuctions.find(
      (auction) => auction.auctionIdentity.auctionTypeId === auctionIdentity.auctionTypeId
      && auction.auctionIdentity.productId === auctionIdentity.productId
      && auction.auctionIdentity.tradingVenueId === auctionIdentity.tradingVenueId,
    );
    this.auctionState$$.next({ ...this.auctionState$$.value, availableAuctionSelected });
  }

  setView(view: AuctionView): void {
    if (this.auctionState$$.value.view === view) {
      return;
    }

    this.auctionState$$.next({ ...this.auctionState$$.value, view });
  }

  setAuctionSummaryGridStateLoading(loading: boolean): void {
    this.auctionState$$.next({
      ...this.auctionState$$.value,
      auctionSummaryGridState: {
        ...this.auctionState$$.value.auctionSummaryGridState,
        loading,
      },
    });
  }

  setAuctionSummaryGridStateLastUpdated(lastUpdated: string): void {
    this.auctionState$$.next({
      ...this.auctionState$$.value,
      auctionSummaryGridState: {
        loading: false,
        lastUpdated,
      },
    });
  }

  setAuctionOrderGridStateLoading(loading: boolean): void {
    this.auctionState$$.next({
      ...this.auctionState$$.value,
      auctionOrderGridState: {
        ...this.auctionState$$.value.auctionOrderGridState,
        loading,
      },
    });
  }

  setAuctionSubmitGridStateLoading(loading: boolean): void {
    this.auctionState$$.next({
      ...this.auctionState$$.value,
      auctionSubmitGridState: {
        ...this.auctionState$$.value.auctionSubmitGridState,
        loading,
      },
    });
  }

  setAuctionOrders(auctionOrders: OrderSummary[]): void {
    this.auctionState$$.next({
      ...this.auctionState$$.value,
      auctionOrders,
    });
  }

  setAuctionOrderSelectedId(auctionOrderSelectedId: string | undefined): void {
    this.auctionState$$.next({
      ...this.auctionState$$.value,
      auctionOrderSelectedId,
    });
  }

  setAuctionResultSelectedId(auctionResultSelectedId: string | undefined): void {
    this.auctionState$$.next({
      ...this.auctionState$$.value,
      auctionResultSelectedId,
    });
  }

  setAuctionCurveOrderDetailSelected(auctionCurveOrderDetailSelected: RetrievedCurveOrderResult | undefined): void {
    this.auctionState$$.next({
      ...this.auctionState$$.value,
      auctionCurveOrderDetailSelected,
    });
  }

  initAuctionResults(auctionOrders: OrderSummary[]): void {
    const auctionResults = auctionOrders.map((order) => ({
      auctionId: order.auctionId,
      auctionState: order.auctionState,
      retrievingAuctionResults: false,
      trades: undefined,
      prices: undefined,
    }));

    this.auctionState$$.next({
      ...this.auctionState$$.value,
      auctionResults,
    });
  }

  setAuctionResultRetrieving(auctionId: string, value: boolean): void {
    this.auctionState$$.next({
      ...this.auctionState$$.value,
      auctionResults: this.auctionState$$.value.auctionResults.map((result) => {
        if (result.auctionId === auctionId) {
          return {
            ...result,
            retrievingAuctionResults: value,
          };
        }

        return result;
      }),
    });
  }

  setAuctionResultState(auctionId: string, auctionState: AuctionResultState): void {
    this.auctionState$$.next({
      ...this.auctionState$$.value,
      auctionResults: this.auctionState$$.value.auctionResults.map((result) => {
        if (result.auctionId === auctionId) {
          return {
            ...result,
            auctionState,
          };
        }

        return result;
      }),
    });
  }

  setSelectedDeliveryName(selectedDeliveryName: string): void {
    this.auctionState$$.next({
      ...this.auctionState$$.value,
      selectedDeliveryName,
    });
  }

  setSelectedPortfolioId(selectedPortfolioId: string): void {
    this.auctionState$$.next({
      ...this.auctionState$$.value,
      selectedPortfolioId,
    });
  }

  setSelectedAuctionId(selectedAuctionId: string): void {
    this.auctionState$$.next({
      ...this.auctionState$$.value,
      selectedAuctionId,
    });
  }

  setSelectedParticipantId(selectedParticipantId: string): void {
    this.auctionState$$.next({
      ...this.auctionState$$.value,
      selectedParticipantId,
    });
  }

  setRefreshSummaryOrder(refresh: boolean): void {
    this.refreshSummaryOrder$$.next(refresh);
  }

  setRefreshOrder(refresh: boolean): void {
    this.refreshOrder$$.next(refresh);
  }

  saveCurveOrder(save: boolean): void {
    this.saveCurveOrder$$.next(save);
  }

  submitCurveOrder(submit: boolean): void {
    this.submitCurveOrder$$.next(submit);
  }

  aggregateOrder(aggregate: boolean): void {
    this.aggregateCurveOrder$$.next(aggregate);
  }

  getAuctionDeliveryDate(auctionDeliveryStart: DateTime | undefined): string | undefined {
    if (!auctionDeliveryStart) {
      return undefined;
    }

    return auctionDeliveryStart.setZone('Europe/Berlin').toFormat('yyyy-MM-dd');
  }
}
