<div class="container">
  <app-bar-container></app-bar-container>

  <mat-drawer-container class="drawer" hasBackdrop="true">
    <mat-drawer #drawer
      mode="over"
      position="end"
      disableClose="true"
      class="drawer__sidenav"
      [class.drawer__sidenav--sp]="selectedDrawerType === 'set-points'">
      @switch (selectedDrawerType) {
        @case ('inbox') {
          <ng-template [cdkPortalOutlet]="inboxPanelPortal"></ng-template>
        }
        @case ('set-points') {
          <ng-template [cdkPortalOutlet]="setPointsPanelPortal"></ng-template>
        }
      }
    </mat-drawer>

    <mat-drawer-content class="drawer-content" [class.has-banner]="((bannerNotifications$ | async)?.length || 0) > 0">
      <app-action-bar-container [editSetPointsVisible]="selectedCardType==='reserve-bidding'"
        [editSetPointsEnabled]="isAssetGroupSelected"
        [deactivateOrdersButtonVisible]="selectedCardType === 'nop-summary' || selectedCardType === 'position'">
      </app-action-bar-container>

      @if (((bannerNotifications$ | async)?.length ||0) > 0) {
        <div>
          <app-error-banner
            class="error-banner"
            [banners]="(bannerNotifications$ | async)">
          </app-error-banner>
        </div>
      }

      <main class="container__main">
        <mat-progress-bar mode="indeterminate"
          class="container__progress-bar"
          [class.container__progress-bar--show]="(showTimeseriesProgress$ | async) || loading$()"
          data-testid="home-progressBar">
        </mat-progress-bar>

        <div class="container__top">
          <app-card-toggle-group-container (selectCard)="onSelectCard($event)">
          </app-card-toggle-group-container>

          <div class="content">
            @if (selectedCardType === 'assets' || selectedCardType === 'reserve-bidding') {
              <app-asset-hierarchy-container [selectedCardType]="selectedCardType"></app-asset-hierarchy-container>
            }
          </div>
        </div>

        <div class="container__middle">
          @switch (selectedCardType) {
            @case ('auction') {
              <app-auction-container/>
            }
            @case ('nop-summary') {
              <ng-template [cdkPortalOutlet]="nopSummaryPortal"></ng-template>
            }
            @case ('position') {
              <ng-template [cdkPortalOutlet]="summaryNopContainerPortal"></ng-template>
            }
            @case ('reserve-bidding') {
              <ng-template [cdkPortalOutlet]="reserveBiddingGraphPortal"></ng-template>
              <ng-template [cdkPortalOutlet]="reserveBiddingGridPortal"></ng-template>
              <ng-template [cdkPortalOutlet]="mfrrGridsPortal"></ng-template>
            }
            @case ('assets') {
              <ng-template [cdkPortalOutlet]="assetContainer"></ng-template>
            }
          }
        </div>
      </main>
    </mat-drawer-content>
  </mat-drawer-container>

  <app-footer-container></app-footer-container>
</div>
